import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import relativeTime from "dayjs/plugin/relativeTime"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { TabsItemType } from "src/components/TabsView"
import { AnalyticsConnectionPayloadType } from "src/services/api/swrHooks/useAnalyticsConnection"
import { AnalyticsConnectorPerfStatsDataPointType } from "src/services/api/swrHooks/useAnalyticsConnectorPerfStats"
import { AnalyticsHostsRelayNodesPerformanceAnomiliesPayloadType } from "src/services/api/swrHooks/useAnalyticsHostsRelayNodesPerformanceAnomilies"
import { LayoutPoints } from "src/services/api/swrHooks/useAnalyticsLayout"
import { AuthenticationPiePayloadTypes } from "src/services/api/swrHooks/useAuthenticationTypesChart"
import { AnalyticsConnectionPayloadTypes } from "src/services/api/swrHooks/useDashboardAnalyticsConnect"
import { APPLICATION_PROTOCOLS_LIST, WEB_ACCESSIBLE_PROTOCOLS } from "./constants"
import { ServiceProtocolListType } from "./utils.types"

dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)

interface DataUsageStatsPayloadType {
  dataPoints: {
    value?: number
    timestamp: string
  }[]
}

type StatsType = {
  count: number
  upPercentage: number
  downPercentage: number
  upCount: number
  downCount: number
}

type ResourcesDistributionsPayloadType = {
  sites: StatsType
  hosts: StatsType
  relayNodes: StatsType
  services: StatsType
  projects: StatsType
}

const PERFORMANCE_INTERVAL = {
  today: 3600000,
  twentyFourHr: 360000,
  last24Hours: 360000,
  oneHr: 600000,
  twoHr: 600000,
  last1Hour: 600000,
  last2Hour: 600000,
  last7Days: 86400000,
  last30Days: 86400000,
}

export type ChartsTimeIntervalFilterType = keyof typeof PERFORMANCE_INTERVAL

export const formatTimePoints = ({
  x,
  y,
  filter,
}: {
  x?: string
  y?: number
  filter: ChartsTimeIntervalFilterType
}) => {
  const dateFormatMap = {
    today: "h A",
    twentyFourHr: "h A",
    last24Hours: "h A",
    oneHr: "H:mm A",
    twoHr: "H:mm A",
    last1Hour: "H:mm A",
    last2Hour: "H:mm A",
    last7Days: "DD/MM/YYYY",
    last30Days: "DD/MM/YYYY",
  }

  if (x === undefined || y === undefined) return { x: 0, y: 0 }

  const dateFormat = dateFormatMap[filter]

  if (dateFormat) return { x: dayjs.utc(x).local().format(dateFormat), y }

  return { x, y }
}

export const getInitials = (fullname?: string): string | undefined => {
  if (!fullname || typeof fullname !== "string") return undefined

  const names = fullname.split(" ")
  const firstChar = names[0].substring(0, 1).toUpperCase()
  const secondChar = names.length > 1 ? names[names.length - 1].substring(0, 1).toUpperCase() : ""

  const initials = `${firstChar}${secondChar}`

  return initials
}

export const getCookie = (cname: string): string => {
  const name = `${cname}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i]
    while (c.charAt(0) === " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

export const deleteCookie = (cname: string) => {
  document.cookie = `${cname}=; Max-Age=0`
}

export const isArray = (data: any): boolean => {
  return Array.isArray(data)
}

export const isObject = (data: any): boolean => {
  return data === Object(data) && !isArray(data) && typeof data !== "function"
}

export const toSnakeCase = (s: string) => s.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const addSpaceToKey = (s: string) => s.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)

export const toCamelCase = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, (letter) => {
    return letter.toUpperCase().replace("-", "").replace("_", "")
  })
}

export const keysToCamelCase = (o: any) => {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: string) => {
      n[toCamelCase(k)] = keysToCamelCase(o[k])
    })

    return n
  }
  if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamelCase(i)
    })
  }

  return o
}

export const keysToCapitialize = (obj: any) => {
  const objEntries = Object.entries(obj)
  const capsEntries = objEntries.map((entry) => [
    addSpaceToKey(entry[0]).replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
    entry[1],
  ])

  return Object.fromEntries(capsEntries)
}

export const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export const getComparator = (sortDirection: "asc" | "desc" | "", orderby: string) => {
  return sortDirection === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderby)
    : (a: any, b: any) => -descendingComparator(a, b, orderby)
}

export const stableSort = (array: any, comparator: any) => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index])
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el: any) => el[0])
}

export const keysToSnakeCase = (o: any) => {
  if (isObject(o)) {
    const n: any = {}

    Object.keys(o).forEach((k: string) => {
      n[toSnakeCase(k)] = keysToSnakeCase(o[k])
    })

    return n
  }
  if (isArray(o)) {
    return o.map((i: any) => {
      return keysToSnakeCase(i)
    })
  }

  return o
}

const SEC_MILLI = 1000
const DAY_SEC_MILLI = SEC_MILLI * 60 * 60 * 24
const HOUR_SEC_MILLI = SEC_MILLI * 60 * 60
const MIN_SEC_MILLI = SEC_MILLI * 60

export const getTimeDifference = (time: string | null) => {
  const timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }
  if (time) {
    const timeLeftMillis = new Date(time).getTime() - new Date().getTime()

    timeLeft.days = Math.floor(timeLeftMillis / DAY_SEC_MILLI)
    timeLeft.hours = Math.floor((timeLeftMillis % DAY_SEC_MILLI) / HOUR_SEC_MILLI)
    timeLeft.minutes = Math.floor((timeLeftMillis % HOUR_SEC_MILLI) / MIN_SEC_MILLI)
    timeLeft.seconds = Math.floor((timeLeftMillis % MIN_SEC_MILLI) / SEC_MILLI)
  }
  return timeLeft
}

export const getRelativeTime = (time: string | null) => {
  if (time) {
    const timeLeft = getTimeDifference(time)

    if (timeLeft.days) {
      return `${timeLeft.days > 0 ? `${timeLeft.days} days` : ""} ${timeLeft.hours > 0 ? `${timeLeft.hours} hrs` : ""} `
    }

    if (timeLeft.hours) {
      return `${timeLeft.hours > 0 ? `${timeLeft.hours} hrs` : ""} ${
        timeLeft.minutes > 0 ? `${timeLeft.minutes} mins` : ""
      } `
    }

    if (timeLeft.minutes) {
      return `${timeLeft.minutes > 0 ? `${timeLeft.minutes} mins` : ""} ${
        timeLeft.seconds > 0 ? `${timeLeft.seconds} secs` : ""
      } `
    }

    return `${timeLeft.seconds > 0 ? `${timeLeft.seconds} secs` : ""} `
  }

  return null
}

export const isInArray = (value: string | boolean, data: (string | boolean)[]): boolean => {
  const updatedValue = typeof value === "boolean" ? value : value?.toLowerCase()
  return data.some((element) => {
    const updatedElement = typeof element === "boolean" ? element : element?.toLowerCase()
    return updatedValue === updatedElement
  })
}

export const findData = ({ data, query, key = "name" }: { data: any; query?: string; key?: string }) => {
  if (!query) {
    return data
  }
  return data.filter((item: any) => {
    const valueToMatch = item && item[key]?.toLowerCase()
    const parsedQuery = query?.trim()?.toLowerCase()
    return valueToMatch.indexOf(parsedQuery) !== -1
  })
}

export const timestampLabelFormatting = (value: string | number, filter: ChartsTimeIntervalFilterType) => {
  if (filter === "last1Hour") {
    return dayjs(value).format("H:mm A")
  }
  if (filter === "last2Hour") {
    return dayjs(value).format("H:mm A")
  }
  if (filter === "last24Hours") {
    return dayjs(value).format("h A")
  }
  if (filter === "today") {
    return dayjs(value).format("h A")
  }
  if (filter === "last7Days") {
    return dayjs(value).format("DD/MM/YYYY")
  }
  if (filter === "last30Days") {
    return dayjs(value).format("DD/MM/YYYY")
  }

  return value
}

export const performanceAnomiliesChartRange = (filter: ChartsTimeIntervalFilterType) => {
  let maxLimit = dayjs().add(1).valueOf()
  const interval = PERFORMANCE_INTERVAL[filter]
  let minLimit = 0

  if (filter === "today") {
    minLimit = dayjs().startOf("day").valueOf()
  } else if (filter === "last7Days") {
    minLimit = dayjs().subtract(6, "day").valueOf()
  } else if (filter === "last30Days") {
    minLimit = dayjs().subtract(30, "day").valueOf()
  } else if (filter === "last1Hour") {
    maxLimit = dayjs().add(1).valueOf()
    minLimit = dayjs().subtract(1, "hour").valueOf()
  } else if (filter === "last2Hour") {
    maxLimit = dayjs().add(2).valueOf()
    minLimit = dayjs().subtract(2, "hour").valueOf()
  } else if (filter === "last24Hours") {
    maxLimit = dayjs().add(24).valueOf()
    minLimit = dayjs().subtract(24, "hour").valueOf()
  }

  return {
    minLimit,
    maxLimit,
    interval,
  }
}

export const mapPerformanceAnomiliesData = (
  data: AnalyticsHostsRelayNodesPerformanceAnomiliesPayloadType | undefined,
) => {
  const cpuPoints = data?.cpuPerformanceStats?.dataPoints.map((item) => ({
    x: dayjs.utc(item.timestamp).local().valueOf(),
    y: item.value,
    connectorName: item.connectorName,
    instanceName: item.instanceName,
  }))

  const memoryPoints = data?.memoryPerformanceStats?.dataPoints.map((item) => ({
    x: dayjs.utc(item.timestamp).local().valueOf(),
    y: item.value,
    connectorName: item.connectorName,
    instanceName: item.instanceName,
  }))

  const chartData = {
    datasets: [
      { backgroundColor: "#f8b16f", label: "CPU", data: cpuPoints },
      {
        backgroundColor: "#ef817f",
        label: "Memory",
        data: memoryPoints,
      },
    ],
  }
  return chartData
}

const CONNECTION_UP_SERIES_CONFIG = {
  backgroundColor: "#66BC53",
  borderColor: "#66BC53",
  fill: "origin",
  label: "Up",
  data: [],
}

const CONNECTION_DOWN_SERIES_CONFIG = {
  backgroundColor: "#F44336",
  borderColor: "#F44336",
  fill: "origin",
  label: "Down",
  data: [],
}

const CONNECTION_PARTIALLY_DOWN_SERIES_CONFIG = {
  backgroundColor: "#b87a00",
  borderColor: "#b87a00",
  fill: "origin",
  label: "Partially Down",
  data: [],
}

const getUniquePoints = (allPoints?: { x: string | number; y?: number }[]) =>
  allPoints?.filter((value, index, self) => index === self.findIndex((t) => t.x === value.x && t.y === value.y))

export const mapConnectionDataLineChart = ({
  data,
  filter,
  canvas,
  entityType,
  containsPartiallyDownKey,
}: {
  data?: AnalyticsConnectionPayloadType
  filter: ChartsTimeIntervalFilterType
  canvas: any
  entityType: string
  containsPartiallyDownKey?: boolean
}) => {
  const upPoints = data?.dataPoints?.map((point) => formatTimePoints({ x: point?.timestamp, y: point?.up, filter }))

  const downPoints = data?.dataPoints?.map((point) => formatTimePoints({ x: point?.timestamp, y: point?.down, filter }))

  const partiallyDownPoints = data?.dataPoints?.map((point) =>
    formatTimePoints({ x: point?.timestamp, y: point?.partiallyDown, filter }),
  )

  const ctx = canvas.getContext("2d")
  const upGradient = ctx.createLinearGradient(0, 0, 0, 200)
  upGradient.addColorStop(0, "rgba(244,67,54,0.4)")
  upGradient.addColorStop(1, "rgba(255, 255, 255, 0)")

  const downGradient = ctx.createLinearGradient(0, 0, 0, 200)
  downGradient.addColorStop(0, "rgba(102,188,83,0.4)")
  downGradient.addColorStop(1, "rgba(255, 255, 255, 0)")

  const partiallyDownGradient = ctx.createLinearGradient(0, 0, 0, 200)
  partiallyDownGradient.addColorStop(0, "rgba(184,122,1,0.4)")
  partiallyDownGradient.addColorStop(1, "rgba(255, 255, 255, 0)")

  const chartData = {
    datasets: containsPartiallyDownKey
      ? [
          {
            backgroundColor: upGradient,
            label: `Up ${entityType}`,
            borderColor: "#74c274",
            data: getUniquePoints(upPoints),
          },
          {
            backgroundColor: downGradient,
            label: `Down ${entityType}`,
            borderColor: "#eb7a7a",
            data: getUniquePoints(downPoints),
          },
          {
            backgroundColor: partiallyDownGradient,
            label: `Partially Down ${entityType}`,
            borderColor: "#ebcd7a",
            data: getUniquePoints(partiallyDownPoints),
          },
        ]
      : [
          {
            backgroundColor: upGradient,
            label: `Up ${entityType}`,
            borderColor: "#74c274",
            data: getUniquePoints(upPoints),
          },
          {
            backgroundColor: downGradient,
            label: `Down ${entityType}`,
            borderColor: "#eb7a7a",
            data: getUniquePoints(downPoints),
          },
        ],
  }

  return chartData
}
export const mapConnectionDataLineCharttypes = ({
  data,
  filter,
  entityType,
}: {
  data?: AnalyticsConnectionPayloadTypes
  filter: ChartsTimeIntervalFilterType
  entityType: "Authentication" | "Network"
}) => {
  const keysSet = new Set()
  // eslint-disable-next-line array-callback-return
  data?.dataPoints?.map((item: any) => {
    if (item) Object.keys(item?.authTypes ?? item?.authStates).map((key) => keysSet.add(key))
  })

  const keysList = [...keysSet]

  const colors = [
    "#EB7A7A",
    "#0A78FF",
    "#20C5BB",
    "#E36CD7",
    "#FFE604",
    "#2B3952",
    "#F8B16F",
    "#9FDB1D",
    "#722ED1",
    "#BA5700",
  ]

  const getColor = (index: number) => colors[index % colors.length]

  const datasets = keysList.map((value, index) => {
    const color = getColor(index)

    const points = data?.dataPoints?.map((point) =>
      formatTimePoints({
        x: point?.timestamp as string,
        y: point?.authTypes?.[value as number] || point?.authStates?.[value as number] || 0,
        filter,
      }),
    )
    return {
      label: value,
      borderColor: color,
      data: getUniquePoints(points),
    }
  })

  const chartData = {
    datasets: datasets,
  }
  return chartData
}

export const mapNetworkDataLineCharttypes = ({
  data,
  filter,
  entityType,
}: {
  data?: AnalyticsConnectionPayloadTypes
  filter: ChartsTimeIntervalFilterType
  entityType: "Authentication" | "Network"
}) => {
  const keysSet = new Set()
  // eslint-disable-next-line array-callback-return
  data?.dataPoints?.map((item: any) => {
    if (item) Object.keys(item?.authTypes ?? item?.authStates).map((key) => keysSet.add(key))
  })
  const keysList = [...keysSet]
  const timezoneName = dayjs.tz.guess()
  const updatedData = data?.dataPoints?.map((trg) => {
    const duplicatTrg = { ...trg }
    duplicatTrg.timestamp = parseInt(dayjs.utc(duplicatTrg.timestamp).tz(timezoneName).format("x"))
    if (duplicatTrg?.authStates) {
      duplicatTrg.authStates = checkKeys(duplicatTrg.authStates, keysList)
    }
    if (duplicatTrg?.authTypes) {
      duplicatTrg.authTypes = checkKeys(duplicatTrg.authTypes, keysList)
    }
    return duplicatTrg
  })

  const result = {
    dataPoints: updatedData,
  }

  const series = keysList.map((value, index) => {
    const color = fetchColor(index)
    const points = result?.dataPoints?.map((point) => {
      let values = Object.values(point?.authTypes ?? point?.authStates)
      let sum = values.reduce((accumulator, value) => {
        return accumulator + value
      }, 0)
      let currentValue = point?.authTypes?.[value as string] || point?.authStates?.[value as string] || 0
      let pointPercentage = (currentValue / sum) * 100
      return {
        x: point?.timestamp,
        y: pointPercentage,
        z: point?.authTypes?.[value as number] || point?.authStates?.[value as number] || 0,
      }
    })
    return {
      type: "line",
      name: value === "PAP" || value === "EAP-TTLS" ? "802.1X - EAP-TTLS (PAP)" : value,
      color:
        value === "Accepted"
          ? "#5DC180"
          : value === "Disconnected"
          ? "#F9C56F"
          : value === "Rejected"
          ? "#FF8C85"
          : color,
      data: points,
    }
  })

  const chartData = series

  return chartData
}

export const mapDataDonutChartTypes = ({
  data,
  filter,
  entityType,
}: {
  data?: AuthenticationPiePayloadTypes
  filter: ChartsTimeIntervalFilterType
  entityType: "Analytics" | "Application"
}) => {
  let result = Object.fromEntries(Object.entries(data!).filter((e) => e[0] !== "total"))
  let dataset = []
  let i = 0
  for (const [key, value] of Object.entries(result!)) {
    let points = {
      name:
        key === "PAP" || key === "EAP-TTLS"
          ? "802.1X - EAP-TTLS (PAP)"
          : entityType === "Application"
          ? key === "telnet"
            ? getTitleCase(key)
            : key.toUpperCase()
          : key.charAt(0).toUpperCase() + key.slice(1),
      y: value,
      color: fetchColor(i),
    }
    i++
    dataset.push(points)
  }
  let series = [{ data: dataset }]

  const chartData = series
  return chartData
}

const checkKeys = (value: any, key: any) => {
  const dupObj = { ...value }
  key.forEach((key: any) => {
    if (!dupObj[key]) {
      dupObj[key] = 0
    }
  })
  return dupObj
}

export const fetchColor = (index: number) => {
  const colors = [
    "#A4DB98",
    "#8981E5",
    "#D099E4",
    "#FC9253",
    "#50BCC8",
    "#F9C56F",
    "#FF8C85",
    "#75BF63",
    "#ABA3FB",
    "#B575CC",
    "#165DFF",
    "#20C5BB",
    "#F8B16F",
    "#8F66C2",
    "#EB7A7A",
    "#118F7A",
    "#93A4C2",
    "#BA5700",
    "#74C274",
    "#722ED1",
    "#EBCD7A",
    "#AA336A",
    "#483248",
    "#915F6D",
  ]
  return colors[index]
}
export const mapConnectionData = (
  data: AnalyticsConnectionPayloadType | undefined,
  filter: ChartsTimeIntervalFilterType,
) => {
  const parsedDataPoints: {
    [key: string]: {
      up: number
      down: number
      partiallyDown: number
      timestampMillis: number
    }
  } = {}
  const upPoints: { x: string | number; y: number; timestampMillis: number }[] = []
  const downPoints: { x: string | number; y: number; timestampMillis: number }[] = []
  const partiallyDownPoints: { x: string | number; y: number; timestampMillis: number }[] = []

  /**
   * Step: 1 --> Convert all time data into hashmap
   */
  data?.dataPoints.forEach((item) => {
    const timeStampLabel = timestampLabelFormatting(item.timestamp, filter)
    if (!parsedDataPoints[timeStampLabel]) {
      parsedDataPoints[timeStampLabel] = {
        up: 0,
        down: 0,
        partiallyDown: 0,
        timestampMillis: dayjs(item.timestamp).valueOf(),
      }
    }
    if (item.up) {
      parsedDataPoints[timeStampLabel].up += item.up
    }
    if (item.down) {
      parsedDataPoints[timeStampLabel].down += item.down
    }
    if (item.partiallyDown) {
      parsedDataPoints[timeStampLabel].partiallyDown += item.partiallyDown
    }
  })

  /**
   * Step: 2 --> Make seperate series for up, down and partiallyDown points
   */
  Object.keys(parsedDataPoints).forEach((dataKey) => {
    upPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].up,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
    downPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].down,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
    partiallyDownPoints.push({
      x: dataKey,
      y: parsedDataPoints[dataKey].partiallyDown,
      timestampMillis: parsedDataPoints[dataKey].timestampMillis,
    })
  })

  const datasets = []

  /**
   * Step: 3 --> Push into datasets and then sort them in ascending order based on the unix timestamp
   */
  if (upPoints.length > 0) {
    datasets.push({
      ...CONNECTION_UP_SERIES_CONFIG,
      data: upPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }
  if (downPoints.length > 0) {
    datasets.push({
      ...CONNECTION_DOWN_SERIES_CONFIG,
      data: downPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }
  if (partiallyDownPoints.length > 0) {
    datasets.push({
      ...CONNECTION_PARTIALLY_DOWN_SERIES_CONFIG,
      data: partiallyDownPoints.sort((a, b) => a.timestampMillis - b.timestampMillis),
    })
  }

  return {
    datasets,
  }
}

export const mapResourceDistributionInfo = (data: ResourcesDistributionsPayloadType | undefined) => {
  const chartData = {
    datasets: [
      {
        label: "Count",
        data: [
          { x: "Sites", y: data?.sites?.count || 0 },
          { x: "Connectors", y: data?.hosts?.count || 0 },
          { x: "Relays", y: data?.relayNodes?.count || 0 },
          { x: "Services", y: data?.services?.count || 0 },
          { x: "Projects", y: data?.projects?.count || 0 },
        ],
        backgroundColor: "#d55c97",
        borderColor: "#d55c97",
        borderWidth: 1,
      },
    ],
  }
  return chartData
}

export const sparkLineData = (
  data: {
    timestamp: string
    value: number
  }[],
  canvas: any,
) => {
  const ctx = canvas.getContext("2d")
  const gradient = ctx.createLinearGradient(0, 0, 0, 50)
  gradient.addColorStop(0, "rgba(42, 150, 243,0.4)")
  gradient.addColorStop(1, "rgba(255, 255, 255, 0)")
  return {
    datasets: [
      {
        backgroundColor: gradient,
        borderColor: "#2a96f3",
        fill: "origin",
        label: "Usage",
        data: data?.map((point) => ({ x: point.timestamp, y: point.value })),
      },
    ],
  }
}

export const mapThroughputData = ({
  recData,
  sendData,
  canvas,
  filter,
}: {
  recData: AnalyticsConnectorPerfStatsDataPointType[]
  sendData: AnalyticsConnectorPerfStatsDataPointType[]
  canvas: any
  filter: ChartsTimeIntervalFilterType
}) => {
  const recPoints = recData?.map((point) => ({
    ...formatTimePoints({ x: point.timestamp, y: point.value, filter }),
    connectorName: point.connectorName,
    instanceName: point.instanceName,
  }))

  const sendPoints = sendData?.map((point) => ({
    ...formatTimePoints({ x: point.timestamp, y: point.value, filter }),
    connectorName: point.connectorName,
    instanceName: point.instanceName,
  }))

  const ctx = canvas.getContext("2d")
  const sendgradient = ctx.createLinearGradient(0, 0, 0, 200)
  sendgradient.addColorStop(0, "rgba(244,67,54,0.4)")
  sendgradient.addColorStop(1, "rgba(255, 255, 255, 0)")

  const recgradient = ctx.createLinearGradient(0, 0, 0, 200)
  recgradient.addColorStop(0, "rgba(102,188,83,0.4)")
  recgradient.addColorStop(1, "rgba(255, 255, 255, 0)")

  const chartData = {
    datasets: [
      {
        backgroundColor: recgradient,
        borderColor: "#66BC53",
        fill: "origin",
        label: "Receive",
        data: recPoints,
      },
      {
        backgroundColor: sendgradient,
        borderColor: "#F44336",
        fill: "origin",
        label: "Send",
        data: sendPoints,
      },
    ],
  }

  return chartData
}

export const mapPerformanceStatsData = ({
  data,
  canvas,
  gradientRGBColor,
  filter,
}: {
  data: AnalyticsConnectorPerfStatsDataPointType[]
  canvas: any
  gradientRGBColor: [number, number, number]
  filter: ChartsTimeIntervalFilterType
}) => {
  const points = data?.map((point) => ({
    ...formatTimePoints({ x: point.timestamp, y: point.value, filter }),
    connectorName: point.connectorName,
    instanceName: point.instanceName,
  }))

  const ctx = canvas.getContext("2d")
  const gradient = ctx.createLinearGradient(0, 0, 0, 200)
  gradient.addColorStop(0, `rgba(${gradientRGBColor[0]}, ${gradientRGBColor[1]}, ${gradientRGBColor[2]},0.3)`)
  gradient.addColorStop(1, "rgba(255, 255, 255, 0)")

  const chartData = {
    datasets: [
      {
        backgroundColor: gradient,
        borderColor: `#007e91`,
        fill: "origin",
        label: "Perentage",
        data: points,
      },
    ],
  }

  return chartData
}

export const mapDataUsageInfo = (
  data: DataUsageStatsPayloadType | undefined,
  filter: ChartsTimeIntervalFilterType,
  canvas: any,
  gradientRGBColor: [number, number, number],
) => {
  const points = data?.dataPoints?.map((point) => {
    if (filter === "today") {
      return { x: dayjs(point.timestamp).format("h A"), y: point.value }
    }
    if (filter === "last7Days") {
      return { x: dayjs(point.timestamp).format("DD/MM/YYYY"), y: point.value }
    }
    if (filter === "last30Days") {
      return { x: dayjs(point.timestamp).format("DD/MM/YYYY"), y: point.value }
    }
    if (filter === "last1Hour") {
      return { x: dayjs(point.timestamp).format("H:mm A"), y: point.value }
    }
    if (filter === "last2Hour") {
      return { x: dayjs(point.timestamp).format("H:mm A"), y: point.value }
    }
    if (filter === "last24Hours") {
      return { x: dayjs(point.timestamp).format("h A"), y: point.value }
    }
    return { x: point.timestamp, y: point.value }
  })

  const ctx = canvas.getContext("2d")
  const gradient = ctx.createLinearGradient(0, 0, 0, 200)
  gradient.addColorStop(0, `rgba(${gradientRGBColor[0]}, ${gradientRGBColor[1]}, ${gradientRGBColor[2]},0.3)`)
  gradient.addColorStop(1, `rgba(${gradientRGBColor[0]}, ${gradientRGBColor[1]}, ${gradientRGBColor[2]},0)`)

  const chartData = data?.dataPoints?.length
    ? {
        datasets: [
          {
            backgroundColor: gradient,
            borderColor: `#007e91`,
            fill: "origin",
            label: "Usage",
            data: getUniquePoints(points),
          },
        ],
      }
    : {
        datasets: [
          {
            fill: "origin",
            label: "Usage",
            data: [
              { x: "6AM", y: 0 },
              { x: "12PM", y: 0 },
              { x: "6PM", y: 0 },
              { x: "12AM", y: 0 },
            ],
          },
        ],
      }

  return chartData
}

const DATA_USAGE_INTERVAL = {
  today: 600,
  twentyFourHr: 600,
  last24Hours: 600,
  oneHr: 60,
  twoHr: 60,
  last1Hour: 60,
  last2Hour: 60,
  last7Days: 43200,
  last30Days: 86400,
}

const RELATIVE_HISTOGRAM_INTERVAL = {
  today: Math.round((dayjs().utc().hour() * 3600 + dayjs().minute() * 60 + dayjs().second()) / 5),
  last24Hours: 17280,
  last1Hour: 720,
  last2Hour: 1440,
  last7Days: 120960,
  last30Days: 518400,
}

export type RelativeHistogramIntervalFilterType = keyof typeof RELATIVE_HISTOGRAM_INTERVAL

export const getRelativeHistogramInterval = (filterValue: RelativeHistogramIntervalFilterType) => {
  let endTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
  let startTime = dayjs().utc().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  const interval = RELATIVE_HISTOGRAM_INTERVAL[filterValue]

  if (filterValue === "last1Hour") startTime = dayjs().utc().subtract(1, "hour").format("YYYY-MM-DD HH:mm:ss")
  else if (filterValue === "last2Hour") startTime = dayjs().utc().subtract(2, "hour").format("YYYY-MM-DD HH:mm:ss")
  else if (filterValue === "last24Hours") startTime = dayjs().utc().subtract(24, "hour").format("YYYY-MM-DD HH:mm:ss")
  else if (filterValue === "last7Days") startTime = dayjs().utc().subtract(7, "day").format("YYYY-MM-DD HH:mm:ss")
  else if (filterValue === "last30Days") startTime = dayjs().utc().subtract(30, "day").format("YYYY-MM-DD HH:mm:ss")

  return {
    endTime,
    startTime,
    interval,
  }
}

export const getTimeLineForDataUsageChart = (filterValue: ChartsTimeIntervalFilterType) => {
  let endTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
  let startTime = dayjs().startOf("day").utc().format("YYYY-MM-DD HH:mm:ss")
  const interval = DATA_USAGE_INTERVAL[filterValue]
  if (filterValue === "last24Hours") {
    startTime = dayjs().utc().subtract(24, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last7Days") {
    startTime = dayjs().utc().subtract(6, "day").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last30Days") {
    startTime = dayjs().utc().subtract(30, "day").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last1Hour") {
    startTime = dayjs().utc().subtract(1, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "last2Hour") {
    startTime = dayjs().utc().subtract(2, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "oneHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(1, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "twoHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(2, "hour").format("YYYY-MM-DD HH:mm:ss")
  } else if (filterValue === "twentyFourHr") {
    startTime = dayjs().utc().format("YYYY-MM-DD HH:mm:ss")
    endTime = dayjs().utc().add(24, "hour").format("YYYY-MM-DD HH:mm:ss")
  }

  return {
    endTime,
    startTime,
    interval,
  }
}

export const calculatePercentage = (totalCount: number, count: number) => {
  if (totalCount === 0) {
    return 0
  }
  return (count / totalCount) * 100
}

export const percentCalculation = (total: number, value: number) => {
  if (total === 0) {
    return 0
  } else {
    const percentage = (value / total) * 100
    const roundedPercentage = Math.round(percentage * 10) / 10
    return roundedPercentage % 1 === 0 ? roundedPercentage.toFixed(0) : roundedPercentage.toFixed(1)
  }
}

export const formatBytes = (bytes: number, decimals = 0) => {
  if (!+bytes) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getFilters = (filters: { key: string; value: string }[]): string => {
  let filtersString = ""
  if (!filters || !filters.length) {
    return filtersString
  }
  filters.forEach(({ key, value }, idx) => {
    const shouldAppendAnd = filters.length > idx + 1 ? "&" : ""
    if (value) filtersString += `${key}=${value}${shouldAppendAnd}`
  })

  return filtersString
}

export const getMaxLayoutYAxisPlacement = (layout: LayoutPoints[]) => {
  const yValues = layout.map((i) => i.y)

  return Math.max(...yValues)
}

export type filterType = "expired" | "aboutToExpire" | "services" | "users"
type filterResponseType = "exclude_by" | "filter_by"
export const filterOption = (type: filterType): filterResponseType => {
  switch (type) {
    case "aboutToExpire":
      return "exclude_by"
    default:
      return "filter_by"
  }
}

export const getProtocolsList = (isAgentless: boolean): ServiceProtocolListType[] =>
  isAgentless
    ? APPLICATION_PROTOCOLS_LIST.map((protocol) =>
        WEB_ACCESSIBLE_PROTOCOLS.includes(protocol.value) ? protocol : { ...protocol, isDisabled: true },
      )
    : APPLICATION_PROTOCOLS_LIST

const ImageExtensions = ["jpeg", "jpg", "png", "bmp", "tif", "tiff"]

export const isImage = (fileExtension?: string): boolean => {
  if (!fileExtension) return false
  return ImageExtensions.includes(fileExtension)
}

export const mapAccessMode = {
  agentbased: "Agent-based",
  agentless: "Agentless",
} as const

export const mapConnectionStatus = {
  ACTIVE: "active",
  IN_ACTIVE: "inActive",
  RESOURCE_DOWN: "resourceDown",
} as const

export const getTabId = (tabsArray: TabsItemType[], route: string) => tabsArray.find((tab) => tab.route === route)?.id

export const mapObjectToDropdown = <DropDownType = {}, ObjectType = {}>(
  array: ObjectType[],
  dropdownArray: DropDownType[],
  keyToCompare: [keyof DropDownType, keyof ObjectType],
  merger?: (obj: ObjectType) => Partial<ObjectType>,
): (DropDownType | any)[] =>
  array
    .map((item) => {
      const current = dropdownArray.find((obj) => obj?.[keyToCompare[0]] === item?.[keyToCompare[1]])
      const keysToMerge = merger?.(item) || {}

      if (current) {
        return { ...current, ...keysToMerge }
      } else {
        return undefined
      }
    })
    .filter((el) => el !== undefined && el !== null)

export const getStringArrayToDropdown = <DropDownType = any>(
  array: (unknown | string)[],
  dropdownArray: DropDownType[],
  keyToCompare: keyof DropDownType,
  extra: Record<string, any> = {},
): (DropDownType | any)[] =>
  array
    .map((item) => {
      const current = dropdownArray.find((obj) => obj[keyToCompare] === item)
      if (current) {
        return { ...current, ...extra }
      } else {
        return undefined
      }
    })
    .filter((el) => el !== undefined && el !== null)

export const getTitleCase = (word: string): string => {
  const [firstLetter = "", ...rest] = word
  return `${firstLetter.toUpperCase()}${rest.join("").toLowerCase()}`
}

export const swapObjectKeysAndValues = (map: any) =>
  Object.fromEntries(Object.entries(map).map(([key, value]) => [value, key]))

// Format a number by adding commas to it
export const formatNumber = (number: number) => number.toLocaleString()

export const isViewMore = (array: unknown[], max: number = 5) => {
  const maxDisplayedRecord = max
  return { showViewMore: array.length - max > 0, items: array?.slice(0, maxDisplayedRecord) }
}

export const isEmptyObject = (obj: Record<string, any>): boolean => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (!isEmptyObject(obj[key])) {
          return false
        }
      } else if (obj[key] !== undefined && obj[key] !== null && obj[key].length !== 0) {
        return false
      }
    }
  }
  return true
}

export const isIntegerWithNoLeadingZero = (strNumber: string) => {
  if (strNumber.startsWith("0") && strNumber !== "0") {
    return false // Has leading zero
  }

  return Number.isInteger(Number(strNumber))
}

export const getXiqUrl = () => {
  return `${sessionStorage.getItem("xiqUrl")}/login`
}

export const getExternalApiUrl = () => {
  return sessionStorage.getItem("xcdUrl")
}

export const isSomething = (data: any) => {
  if (data === null || data === undefined) return false

  if (typeof data === "number") return true

  if (typeof data === "string" && data.length === 0) return false

  if (typeof data === "object" && Object.keys(data).length === 0) return false

  if (Array.isArray(data) && data.length === 0) return false

  return true
}

export const capitalize = (str: string) => {
  return str.replace(/\b\w/g, (char) => char.toUpperCase())
}
